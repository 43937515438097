var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products category-med category-last"},[_c('Breadcrumb',{attrs:{"items":_vm.breadcrumb}}),_c('category-block',{staticClass:"category-block category-block-1",attrs:{"target":_vm.category,"position":"position1"}}),_c('CategoryTitle',{attrs:{"category":_vm.category,"count":_vm.count}}),_c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[(_vm.parentCategory)?_c('v-chip-group',{staticClass:"py-3 category-chip",attrs:{"show-arrows":""}},_vm._l((_vm.parentCategory.categories),function(subCategory){return _c('v-chip',{key:subCategory.categoryId,staticClass:"mr-2 mb-2",attrs:{"link":"","exact-active-class":"grey darken1","to":_vm.category.navigationTypeId == 0
            ? { name: 'Category', params: { pathMatch: subCategory.slug } }
            : {
                name: 'Category',
                params: {
                  pathMatch: _vm.category.slug
                },
                query: { categoryId: subCategory.categoryId }
              }}},[_c('span',{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(subCategory.name))])])}),1):_vm._e(),_c('ProductListGrid',{key:_vm.category.categoryId,attrs:{"parentCategoryId":_vm.category.categoryId,"hideFilterString":"Main Term","pageSize":_vm.$ebsn.meta(_vm.category, 'template_model.PAGE_SIZE'),"virtualScrollViewport":_vm.$vuetify.breakpoint.xsOnly,"virtualPageSize":_vm.$vuetify.breakpoint.xsOnly ? 4 : 1},on:{"productsCount":_vm.updateCount}})],1),_c('category-block',{staticClass:"category-block category-block-2",attrs:{"target":_vm.category,"position":"position2"}}),_c('category-block',{staticClass:"category-block category-block-3",attrs:{"target":_vm.category,"position":"position3"}}),_c('v-container',[_c('ebsn-meta',{attrs:{"target":_vm.category,"path":"category_info.FOOTER_TEXT","tag":"div"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }